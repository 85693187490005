
function makeString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g);
};

const validateMobile = (mobile) => {
    return mobile.match(/^\d{10}$/)
};


export const Helpers = {
    makeString,validateEmail,validateMobile
};

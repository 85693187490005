import { createStore } from 'vuex'

const user = localStorage.getItem('nrsllrcust');
const initialState = user ? true : false
let initialUserData ={}
let initialUserLocation = {lat:"0.00",lng:"0.00"}
let initialLocationTxt = ""
console.log(user)
if(user){
   initialUserData = JSON.parse(user);
}

export default createStore({
  state: {
    'userLoggedin': initialState,
    'userData': initialUserData,
    'userLocation': initialUserLocation,
    'userLocationTxt' : initialLocationTxt
  },
  getters: {
  },
  mutations: {
    logOutUser: state => {
      state.userLoggedin = initialState
    },
    logInUser: state => {
      state.userLoggedin = true
    },
    changeUserData:(state,payload) => {
      state.userData = payload
    },
    changeUserLocation:(state,payload) => {
      state.userLocation = payload
    },
    changeLocationTxt:(state,payload) => {
      state.userLocationTxt = payload
    }
  },
  actions: {
    logOutUser: context => {
      context.commit('logOutUser')
    },
    logInUser: context => {
      context.commit('logInUser')
    },
    changeUserData:(context,payload) => {
      context.commit('changeUserData',payload)
    },
    changeUserLocation:(context,payload) => {
      context.commit('changeUserLocation',payload)
    },
    changeLocationTxt:(context,payload) => {
      context.commit('changeLocationTxt',payload)
    }
  },
  modules: {
  }
})

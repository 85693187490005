import { createRouter, createWebHistory } from 'vue-router'
import { uzr } from '@/user/User';

let loguser = uzr.currentUser()
//var perm = {}

if (loguser) {
    //perm = loguser.perm
}

const routes = [
    {
        path: '/',
        redirect: 'home',
        component: () => import('@/layouts/DefaultLayout.vue'),
        meta: { requiresAuth: false, },
        children: [

            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/storefront/HomePage.vue'),
                meta: { has_perm: true, },
            },
            // {
            //     path: '/login',
            //     name: 'login',
            //     component: () => import('@/views/auth/LoginPage.vue'),
            //     meta: { has_perm: true, },
            // },


            {
                path: '/products-near-you',
                name: 'products-near-you',
                component: () => import('@/views/storefront/ProductsPage.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/products/:slug?',
                name: 'products',
                component: () => import('@/views/product/ProductFilter.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/p/:productslug?',
                name: 'productdetails',
                component: () => import('@/views/product/ProductDetails.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/services-near-you',
                name: 'services-near-you',
                component: () => import('@/views/storefront/ServicesPage.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/s/:serviceslug?',
                name: 'servicedetails',
                component: () => import('@/views/services/ServiceDetails.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/search-products/:searchkey',
                name: 'keyword-product-search',
                component: () => import('@/views/storefront/keywordSearch.vue'),
                meta: { has_perm: true }
            },
            

            {
                path: '/services/:slug?',
                name: 'services',
                component: () => import('@/views/services/ServicesFilter.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/shops',
                name: 'shops',
                component: () => import('@/views/storefront/ShopsPage.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/bestdeals',
                name: 'bestdeals',
                component: () => import('@/views/storefront/BestDeals.vue'),
                meta: { has_perm: true, },
            },

            {
                path: '/legal/privacy-policy',
                name: 'privacy-policy',
                component: () => import('@/views/legal/privacyPolicy.vue'),
                meta: { has_perm: true, },
            },
            {
                path: '/contact-us',
                name: 'contact-us',
                component: () => import('@/views/contact_us/contactUs.vue'),
                meta: { has_perm: true, },
            },

           

        ]
    },

    {
        path: "/",
        component: () => import('@/layouts/DefaultLayout.vue'),
        meta: { requiresAuth: false, },
        children: [
            {
                path: 'account/:pagetype',
                name: 'account',
                component: () => import('@/views/customer/MyProfile.vue'),
                meta: { has_perm: true, },
            },
           
            {
                path: 'account/cart',
                name: 'cart',
                component: () => import('@/views/customer/MyCart.vue'),
                meta: { has_perm: true, },
            },
        ]
    },

    // {
    //     path: '/auth',
    //     name: 'Auth',
    //     component: () =>
    //         import('@/layouts/AuthenticationLayout.vue'),
    //     meta: {
    //         requiresAuth: false,
    //         has_perm: true,
    //     },
    //     children: [{
    //         path: '/login/',
    //         name: 'login',
    //         component: () => import('@/views/auth/LoginPage.vue')
    //     },
    //     ]
    // },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/NotFound.vue"),
        meta: { has_perm: true, },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    // const publicPages = ['/login'];
    const authRequired = to.matched.some(record => record.meta.requiresAuth)
    const loggedIn = localStorage.getItem('nrsllrcust');
    const hasPermission = to.matched.some(record => record.meta.has_perm)

    //console.log(authRequired);
    if (authRequired && !loggedIn) {
        const path = ''
        next('/' + path);
    } else if (!hasPermission) {
        return next('/not-found');
    } else {
        next()
    }
})

export default router